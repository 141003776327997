import React from "react";
import Layout from "../components/layout";
import AccentFrame from "../components/accentFrame";
import Definition from "../components/definition";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import InformationTable from "../components/informationTable";
import { graphql } from 'gatsby';
import {
  Typography,
} from "@material-ui/core"
import { Section } from "../components/section"

export default function Diagnostic() {
  const { t } = useTranslation()
  const columns = [t("Tests"), t("Chorionic villus biopsy"), t("Amniocentesis")]
  const rows = [
    [
      t("Definition"),
      t("A chorionic villus biopsy, or choriocentesis, consists of taking a small sample of the chorionic cells (cells of the future placenta) under ultrasound guidance by aspirating them vaginally, in order to analyze the chromosomes."),
      t("Amniocentesis consists of taking")
    ],
    [
      t("Time period in which the test is performed"),
      t("Between the 10th and 13th week of pregnancy."),
      t("From the 15th week of pregnancy.")
    ],
    [
      t("Test reliability"),
      t("The result is reliable in almost 100% of cases. In 1-2% of cases, the chromosomal abnormalities detected by choriocentesis are not a reflection of the fetal chromosomes."),
      t("The result is reliable in almost 100% of cases.")
    ],
    [
      t("Waiting period"),
      t("About three days for a rapid diagnostic test."),
      t("About three days for a rapid diagnostic test.")
    ],
    [
      t("Risk to pregnancy"),
      t("Major complication: risk of miscarriage (1% of cases). Minor complications: bleeding, uterine contractions, abdominal pain."),
      t("Major complication: risk of miscarriage (0.2% to 0.5% of cases, i.e. between 1 in 500 and 1 in 200 cases). Minor complications: slight loss of amniotic fluid, uterine contractions, abdominal pain.")
    ],
    [
      t("Availability"),
      t("Depends on the presence of a specialized laboratory to do the analysis."),
      t("In all regions, but depends on the presence of a trained obstetrician or other specialist and ultrasound guidance.")
    ]
  ]

  return (
    <Layout pageTitle={t("Diagnostic")} next="/diagnostic/part1">
      <Section title={t("Prenatal diagnostic test")}>
          <Typography>
            {t("A prenatal diagnostic test is offered only to pregnant women with a high probability of having a child with a trisomy.")}
            {" "}
              <Trans i18nKey="diagnosticDef">
                The prenatal diagnostic test is an <Definition word="invasive">invasive</Definition> procedure, as it requires the introduction of a needle into the uterus through the abdomen or vagina. 
                It consists of taking cells from the fetus, under ultrasound control, to access all of its genetic information.
                It allows us to learn whether or not there is a genetic anomaly or disease in the fetus.
              </Trans>
              <br/>
              <br/>
              {t("It allows us to learn whether or not there is a genetic anomaly or disease in the fetus.")}
          </Typography>
      </Section>
      <Section title={t("Prenatal diagnostic techniques")}>
          <Typography component="span" gutterBottom={true}>
              {t("There are two techniques for prenatal diagnosis. You may be offered one or the other depending on the stage of your pregnancy.")}
          </Typography>
          <InformationTable rows={rows} columns={columns} firstColumnWidth="140px"/>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
